<template>
  <component :is="currentComponent"></component>
</template>

<script>
export default {
  name: 'DemoPage',
  data() {
    return {};
  },
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'register/Demo');
    }
  }
};
</script>
